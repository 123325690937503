import React, { useState, useEffect } from 'react';
import Spline from '@splinetool/react-spline';
import Chat from './components/Chat';
import styled, { keyframes, css } from 'styled-components';

const AppContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const SplineContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  animation: ${fadeIn} 2s forwards;
  animation-delay: 5s; // Start fading in after 5 seconds
`;

const LoadingTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: white;
  animation: ${fadeInOut} 3s infinite;
  z-index: 0; // Lower than the icon
  ${props =>
    props.isFadingOut &&
    css`
      animation: ${fadeOut} 2s forwards;
    `}
`;

const ChatOverlay = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 30%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px 0;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  z-index: 2;
`;

function App() {
  const [chatVisible, setChatVisible] = useState(false);
  const [splineLoaded, setSplineLoaded] = useState(false);
  const [textFadingOut, setTextFadingOut] = useState(false);

  useEffect(() => {
    const chatTimer = setTimeout(() => {
      setChatVisible(true);
    }, 5000);

    return () => clearTimeout(chatTimer);
  }, []);

  useEffect(() => {
    if (splineLoaded) {
      const textTimer = setTimeout(() => {
        setTextFadingOut(true);
      }, 5000);

      return () => clearTimeout(textTimer);
    }
  }, [splineLoaded]);

  return (
    <AppContainer>
      <SplineContainer>
        <Spline
          scene="https://prod.spline.design/wm9PNDgX3X2ePdfm/scene.splinecode"
          onLoad={() => setSplineLoaded(true)}
        />
      </SplineContainer>

      <LoadingTextWrapper isFadingOut={textFadingOut}>
        Dexter AI
      </LoadingTextWrapper>

      <ChatOverlay visible={chatVisible}>
        <Chat />
      </ChatOverlay>
    </AppContainer>
  );
}

export default App;