import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import styled from 'styled-components';

const socket = io('https://cap.wf:5000');

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #1a1a1a; // Dark background
  border-radius: 10px 0 0 10px;
  overflow: hidden;
`;

const ChatWindow = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%;
  color: #ffffff; // White text
  ${props => props.isUser ? `
    align-self: flex-end;
    background-color: #4B0082; // Deep purple for user messages
  ` : `
    align-self: flex-start;
    background-color: #2c2c2c; // Darker gray for bot messages
  `}
`;

const InputContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #2c2c2c; // Darker gray
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #3a3a3a; // Slightly lighter gray
  color: #ffffff; // White text
  &::placeholder {
    color: #a0a0a0; // Light gray for placeholder
  }
`;

const SendButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6a0dad; // Lighter purple
  color: #FFFFFF; // White text
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #8a2be2; // Brighter purple on hover
  }
`;

const AudioPlayer = styled.audio`
  width: 100%;
  margin-top: 10px;
`;

const ThinkingMessage = styled.div`
  align-self: flex-start;
  color: #a0a0a0;
  font-style: italic;
  margin-bottom: 10px;
`;

const IconLink = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  z-index: 10; // Ensure it's on top of other elements
  position: relative; // Ensure it respects z-index
  cursor: pointer;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 10px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`;

function Chat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [typingMessage, setTypingMessage] = useState(null);
  const [isThinking, setIsThinking] = useState(false);
  const typingTimeoutRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    socket.on('message', (message) => {
      setIsThinking(false);
      if (!message.isUser) {
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }

        setTypingMessage({ text: '', fullText: message.text, audioUrl: message.audioUrl });
        typeMessage(message.text, message.audioUrl);
      } else {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    socket.on('error', (error) => {
      console.error('Error:', error);
      setIsThinking(false);
    });

    return () => {
      socket.off('message');
      socket.off('error');
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  const typeMessage = (fullText, audioUrl, index = 0) => {
    if (index === 0 && audioRef.current) {
      audioRef.current.src = `http://cap.wf:5000/${audioUrl}`;
      audioRef.current.play();
    }

    if (index <= fullText.length) {
      setTypingMessage((prevTyping) => ({
        ...prevTyping,
        text: fullText.slice(0, index)
      }));

      typingTimeoutRef.current = setTimeout(() => {
        typeMessage(fullText, audioUrl, index + 1);
      }, 50); // Adjust this value to change typing speed
    } else {
      setMessages((prevMessages) => [...prevMessages, { text: fullText, isUser: false, audioUrl }]);
      setTypingMessage(null);
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (input.trim()) {
      setIsThinking(true);
      socket.emit('sendMessage', input);
      setMessages((prevMessages) => [...prevMessages, { text: input, isUser: true }]);
      setInput('');
    }
  };

  const handlePumpIconClick = () => {
    window.open("https://pump.fun/", "_blank");
  };

  const handleXIconClick = () => {
    window.open("https://x.com/", "_blank");
  };

  return (
    <ParentContainer>
      <ChatContainer>
        <ChatWindow>
          {messages.map((message, index) => (
            <Message key={index} isUser={message.isUser}>
              {message.text}
            </Message>
          ))}
          {typingMessage && (
            <Message isUser={false}>
              {typingMessage.text}
            </Message>
          )}
          {isThinking && (
            <ThinkingMessage>Thinking...</ThinkingMessage>
          )}
        </ChatWindow>
        <InputContainer>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage(e)}
            placeholder="Type your message..."
          />
          <SendButton onClick={sendMessage}>Send</SendButton>
        </InputContainer>
        <audio ref={audioRef} style={{ display: 'none' }} />
      </ChatContainer>
      <IconLink>
        <Icon
          src="https://pump.fun/_next/image?url=%2Flogo.png&w=64&q=75"
          alt="Pump Icon"
          onClick={handlePumpIconClick}
        />
        <Icon
          src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/x-social-media-white-icon.png"
          alt="X Icon"
          onClick={handleXIconClick}
        />
      </IconLink>
    </ParentContainer>
  );
}

export default Chat;